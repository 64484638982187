import React from "react";
import Layout from "../../../../components/Layout";
import Seo from "../../../../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ogIMage from "../../../../images/og.png";

const OurStoryImpactThree = () => {
    return (
        <Layout>
            <Seo
                title={" Unleashing talents of young women "}
                description="See our commitment to fostering diversity in technology as we empower young women to showcase their skills, innovate, and make a lasting impact in the world of tech. "
                image={ogIMage}
            />
            <section className="csr-our-stories">
                <div className="container container--first-v3">
                    <div className="csr-our-stories__text gap-2x">
                        <div className="text-p3 gap-2x csr-our-stories__text-breadcrumbs text-fw-normal">
                            <Link to={`/about/csr`} className="text-decor-none">
                                CSR
                            </Link>
                            /<Link className="text-decore none">Our Stories Of Impact</Link>
                        </div>
                        <h1 className="text-h1 text-clr-primary text-fw-medium gap-2x">
                            Breaking Barriers, Unleashing Talents: Sedin Backs All Girls Hackathon
                        </h1>
                        <div className="csr-our-stories__banner-img gap-3x">
                            <StaticImage
                                src="../../../../images/csrpage/our-stories-banner-img-3.png"
                                alt="banner-img"
                                loading="lazy"
                                placeholder="blurred"
                                className="banner-img"
                            />
                        </div>
                        <h2 className="text-h2 text-clr-primary text-fw-medium gap-1x">
                            Our Compassion in Action
                        </h2>
                        <p className="text-p3 text-clr-secondary text-fw-medium gap-1x">
                            It is with great pride and joy that Sedin Technologies commemorates its
                            involvement in sponsoring the groundbreaking All Girls Hackathon, in
                            partnership with TechDiva Foundation. This transformative event is
                            geared towards inspiring and empowering girls, young women, and mothers
                            from low-income communities to embark on a journey of coding and
                            discovery.
                        </p>
                        <p className="text-p3 text-clr-secondary text-fw-medium gap-1x">
                            By providing access to coding education and technology resources, we
                            hope to cultivate a passion for STEM subjects among girls and women who
                            might not have had the opportunity before.
                        </p>
                        <p className="text-p3 text-clr-secondary text-fw-medium gap-1x">
                            Through a series of coding challenges, participants will have the
                            opportunity to dive into the exciting world of programming, while honing
                            their logical thinking and problem-solving skills. 
                        </p>
                        <p className="text-p3 text-clr-secondary text-fw-medium gap-1x">
                            We are committed to building a world where every girl's potential is
                            realized and celebrated.
                        </p>
                    </div>
                </div>
            </section>
            {/* our-stories-image-gallery section */}
            <section className="csr-our-stories-img-gallery">
                <div className="container container--last">
                    <div className="csr-our-stories-img-gallery__grid">
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-03-1.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-03-2.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-03-3.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-03-4.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-03-5.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default OurStoryImpactThree;
